<template>
<div class="ranges">
  <ul v-if="ranges" class="hidden-xs">
      <li
        v-for="(date, key) in ranges"
        @click="$emit('clickRange', date.value)"
        :data-range-key="key"
        :key="key"
      >{{date.label}}</li>
  </ul>
</div>
</template>

<script>
export default {
  props: ['ranges']
}
</script>
